<template>
  <div class="container text-center bg-white pt-4">
    <div class="container_main px-5 justify-content-center d-flex flex-column">

      <div>

        <h1 class="mb-4">
          Email those results<br>
          to yourself and<br>
          <strong>share with your doctor.</strong>
        </h1>
          
        <b-form @submit.stop.prevent="submit">

          <b-form-group :class="{error: errors.includes('name')}">
            <b-form-input
              id="input-name"
              v-model="form.name"
              type="text"
              placeholder="Enter your name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group :class="{error: errors.includes('email')}">
            <b-form-input
              id="input-email"
              v-model="form.email"
              type="email"
              placeholder="Enter your email"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group :class="{error: errors.includes('shareName')}">
            <b-form-input
              id="input-shareName"
              v-model="form.shareName"
              type="text"
              placeholder="Enter your doctor's name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group :class="{error: errors.includes('shareEmail')}">
            <b-form-input
              id="input-shareEmail"
              v-model="form.shareEmail"
              type="email"
              placeholder="Enter your doctor's email"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group :class="{error: errors.includes('checked')}">
            <b-form-checkbox-group
              v-model="form.checked"
              id="input-checkboxes"
            >
              <b-form-checkbox value="checkedValue">Lorem ipsum dolor sit amet.</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

        </b-form>

      </div>

    </div>
    <div class="container_footer p-3">
      <b-button variant="outline-primary" class="d-block w-100 p-2 mb-2" @click="$router.push('/main/result')">Back</b-button>
      <b-button variant="primary" class="d-block w-100 p-2" @click="submit">Send</b-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  middleware: 'check-selection',
  
  data() {
    return {
      form: {
        name: '',
        email: '',
        shareName: '',
        shareEmail: '',
        checked: [],
      },
      errors: [],
    }
  },

  methods: {

    async submit() {
      const { data } = await axios.post('/api/share.php', this.form)
      this.errors = data.errors ? data.errors : []
      if (data == 'success') {
        this.$router.push('/shareConfirmation')
      }
    }

  },
}
</script>